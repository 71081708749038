import {store} from "@arboxappv4/shared/src/redux/store";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import React from "react";
import dayjs from "dayjs";
import {t} from "i18next";
import {bookingTypes} from "@arboxappv4/shared/src/helpers/constants";
import UserAvatar from "../components/UserAvatar";
import {FlexRowSection, SmallText} from "../styles/globalStyles";
import Bugsnag from "@bugsnag/js";

export const getIdentifierFromSubDomain = () => {
    const host = window.location.host;
    const hostArr = host.split('.')
    if(hostArr.length && !hostArr[0].includes('localhost')) {
        return hostArr[0]
    }
    throw 'no identifier!!'
}

export function isLocationIL() {
    const location = store.getState().site.selectedLocation;
    return location?.country_code === 'IL'
}

export const getBoxPageOptions = () => {
    const box = store.getState().site.box;
    let info = {
        hasMemberships: false,
        hasProducts: false,
        hasAvailabilities: false,
        hasSpaceAvailabilities: false,
        hasCourses: false,
        hasClasses: box?.scheduleTypes.hasClasses,
    }
    if(box) {
        box.locations_box.map(location => {
            if(location.hasAvailability > 0) info.hasAvailabilities = true
            if(location.hasSpaceAvailability > 0) info.hasSpaceAvailabilities = true
            if(location.hasHugim > 0) info.hasCourses = true
            if(location.hasProducts > 0) info.hasProducts = true
            if(location.hasMemberships > 0) info.hasMemberships = true
        })
    }
    return info
}

export const getOptionalRoutesArray = () => {
    let options = []
    const info = getBoxPageOptions()
    if(info.hasClasses) options.push({to: 'group', name: 'group-classes'})
    if(info.hasAvailabilities && info.hasSpaceAvailabilities) options.push({name: 'appointments', children: [{key: 'staff-appointment', label: t('staff-appointment')}, {key: 'space-appointment', label: t('space-appointment')}]})
    else if(info.hasAvailabilities) options.push({to: 'staff-appointment', name: 'appointments'})
    else if(info.hasSpaceAvailabilities) options.push({to: 'space-appointment', name: 'appointments'})
    if(info.hasCourses) options.push({to: 'course', name: 'courses'})
    if(info.hasMemberships) options.push({to: 'membership', name: 'memberships'})
    if(info.hasProducts) options.push({to: 'shop', name: 'shop'})
    return options
}

export const getHourIcon = () => {
    const now = dayjs()
    return now.hour() > 5 && now.hour() < 17 ? 'sun-bright' : 'moon-stars'
}

export const checkIfDateBeforeToday = (current) => {
    const currMoment = dayjs(current.format(DB_DATE_FORMAT), DB_DATE_FORMAT)
    const today = dayjs()
    return currMoment.diff(today, 'days') < 0
}

export const checkIfDateAfterToday = (current) => {
    const currMoment = dayjs(current.format(DB_DATE_FORMAT), DB_DATE_FORMAT)
    const today = dayjs()
    return currMoment.diff(today, 'days', true) > 0
}

export const calcItemTax = (item) => {
    const taxes = item.membership_types_taxes.map(item => item.taxes);
    if (taxes.length > 0) {
        return taxes.reduce((sum, tax) => {
            return sum + ((tax.percentage / 100) * item.price)
        }, 0)
    }
    return 0;
}


export const concatCartItems = (cart1, cart2) => {
    if(cart1 && cart2 && cart1.length && cart2.length) {
        return cart1.reduce((acc, item) => {
            const mirror = cart2.find(i => i.id === item.id)
            return [...acc, {...item, ...mirror}]
        }, [])
    }
    return []
}

export const getBoxLogoReplacement = (string) => {
    if (string.length <= 21) return string;
    const subStr = string.slice(0, 21);
    return subStr.slice(0, subStr.lastIndexOf(' ')) || subStr;
}

export const getLocationsForPurchase = (checkRegisterWithoutMembership = true, HPGroupId = null) => {
    const box = store.getState().site.box;
    if(box) {
        if(HPGroupId) {
            return box.paymentGroups[HPGroupId].locations.map(item => ({...item, value: item.id, label: item.location}))
        }
        return box.locations_box.filter(location => {
            if (checkRegisterWithoutMembership && !location.hasMemberships) {
                // if location has no memberships, check if it has register without membership prop
                return location.disable_pages_app.some(prop => prop.section_name === 'registerWithoutMembership')
            } else {
                return location.hasMemberships
            }
        }).map(item => ({...item, value: item.id, label: item.location}))
    }
    return []
}

export const getHPGroupsForPurchase = () => {
    const box = store.getState().site.box;
    if(box) {
        return Object.values(box.paymentGroups).map(item => ({...item.group, value: item.group.id, label: item.group.name}))
    }
    return []
}

export const checkIfHPGroupDisplay = () => {
    const box = store.getState().site.box;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return box?.paymentGroups && Object.keys(box?.paymentGroups).length > 1 && params.paymentGroups === 'true'
}

export const getHPGroupIdFromLocation = (locationId) => {
    const box = store.getState().site.box;
    if(box && locationId) {
        const selectedLocationGroupObj = Object.values(box.paymentGroups).find(groupObj => groupObj.locations.some(loc => loc.id === locationId));
        return selectedLocationGroupObj?.group?.id
    }
    return null
}

export const addFiltersToQueryParams = (navigate, params = {}) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const originalParams = Object.fromEntries(urlSearchParams.entries());
    const newParams = {...originalParams, ...params}
    const queryString = Object.keys(newParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(newParams[key])}`).join('&');
    const newUrl = `${window.location.pathname}?${queryString}`;
    navigate(newUrl, { replace: true, state: window.history.state?.usr || {} }); // Preserve the existing state
}

export const removePropFromQueryParams = (navigate, keyToRemove) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const originalParams = Object.fromEntries(urlSearchParams.entries());
    delete originalParams[keyToRemove]
    const queryString = Object.keys(originalParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(originalParams[key])}`).join('&');
    const newUrl = `${window.location.pathname}?${queryString}`;
    navigate(newUrl, { replace: true });
}

export const onLinkClicked = (to, navigate, params = {}) => {
    if(`/${to}` === window.location.pathname) {
        window.location.reload()
    } else {
        navigate(`${to}${window.location.search}`, params)
    }
}

export const getMembershipPriceText = (item, currencySymbol) => {
    let text = ''
    if(item.price === 0) {
        return t('free')
    } else {
        if(item.is_recurring_payment) {
            text = t('recurring-price-label',{price: `${currencySymbol} ${item.price.toLocaleString()}`,cycle: t(`cycle-${item.recurring_cycle}`)})
        } else if(item.sessions) {
            text = t('session-price-label',{price: `${currencySymbol} ${item.price.toLocaleString()}`,sessions: item.sessions})
        } else {
            text = `${currencySymbol} ${item.price.toLocaleString()}`
        }
        const taxAmount = getTaxPercentage(item)
        text += taxAmount ? ` (${t('added-tax-percent', {tax: taxAmount})})` : ''
        return text;
    }
}

export const getMembershipExpirationText = (item) => {
    if(item.period_time_unit) {
        const period = item.period_amount > 1 ? t(`period_${item.period_time_unit.toLowerCase()}_plural`) : t(`period_${item.period_time_unit.toLowerCase()}`);
        return t('membership-period', {period: item.period_amount > 1 ? `${item.period_amount} ${period}` : period})
    } else {
        return t('unlimited')
    }
}

const getTaxPercentage = (item) => {
    const taxes = item.membership_types_taxes.map(item => item.taxes);
    if(taxes.length) {
        return taxes.reduce((acc, tax) => acc + tax.percentage, 0)
    }
    return 0
}

//note: this function is from the internet, i have no idea what is the logic here, but it works
export const isValidIsraeliID = (idStr) => {
    let id = String(idStr).trim();
    if (id.length > 9 || id.length < 5 || isNaN(idStr)) return false;

    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

    return Array
        .from(id, Number)
        .reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
}

export const getBookingCtaText = (bookingType) => {
    switch (bookingType) {
        case bookingTypes.INSERT_SCHEDULE_USER:
            return t('book')
        case bookingTypes.CANCEL_SCHEDULE_USER:
            return t('cancel-booking')
        case bookingTypes.PAST:
            return t('event-passed')
        case bookingTypes.CANCEL_WAIT_LIST:
            return t('cancel-wait-list')
        case bookingTypes.INSERT_STAND_BY:
            return t('insert-wait-list')
        default:
            return t('book')
    }
}

export const getUserInitials = (user) => {
    let initials = ''
    if(user?.first_name) {
        initials += user.first_name[0]
    }
    if(user?.last_name) {
        initials += user.last_name[0]
    }
    return initials
}

export const sortCourseEventDays = (days) => {
    let sorted = [...days]
    sorted.sort((day1, day2) => {
        if(!isLocationIL()) {
            day1 = day1.day_of_week === 0 ? 7 : day1.day_of_week;
            day2 = day2.day_of_week === 0 ? 7 : day2.day_of_week;
        }
        return day1 - day2
    })
    return sorted
}

export const getCourseInfoArray = (course, showCoaches = true, showCustomFields = true) => {
    const info = {}
    if(showCoaches && course.coach) {
        info.coach = <UserAvatar user={course.coach} nameType={'full'}/>
    }
    if(showCoaches && course.second_coach) {
        info.second_coach = <UserAvatar user={course.second_coach} nameType={'full'}/>
    }
    if(showCustomFields && course.custom_field_value) { //different layout, therefore not using customFields component
        info.customFields = <div>{course.custom_field_value.filter(field => field.value).map(field => <FlexRowSection style={{ paddingTop: '5px' }}>
                                                                            <SmallText weight={'bold'}>{field.custom_field.name}: </SmallText> {field.custom_field.props.options.find(option => option.value === field.value)?.label}
                                                                        </FlexRowSection>)}</div>
    }
    return info
}

export const callSiteLog = (name = 'site-error', data = {}, group = 'default-error-group') => {
    try {
        Bugsnag.notify(new Error(name), event => {
            event.addMetadata('log data', data);
            event.addMetadata('group', {name: group});
            event.context = group;
        })
    } catch (e) {
        console.log('error logging to bugsnag', e)
    }
}